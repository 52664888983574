body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.strike {
  text-decoration: line-through;
}

ul {
  list-style-type: none;
}

label {
  margin: 0.5em;
  display: block;
}

.fui-Input {
  margin: 0.5em;
}

.moodForm {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

#root {
  min-height: calc(100vh - 5rem);
}

#disclaimer {
  position: relative;
  left: 0;
  bottom: 0;
  padding: 0.5rem;
  border-top: solid 1px #5b5fc6;
  text-align: center;
}

.user {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 1em;
}